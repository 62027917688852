<template>
    <nav class="navbar" role="navigation" aria-label="main navigation">
        <div class="navbar-brand">
            <img src="@/assets/tom-logo.png" class="py-2">

            <a 
                role="button" 
                class="navbar-burger" 
                aria-label="menu" 
                aria-expanded="false"
                :class="{ 'is-active': menuActive }"
                @click.prevent="openMenu()"
            >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            </a>
        </div>

        <div class="navbar-menu" :class="{ 'is-active': menuActive }" @click="menuActive = !menuActive"> 
            <div class="navbar-start">
            <RouterLink to="/vpis" class="navbar-item">Nov zapis</RouterLink>
            <RouterLink to="/seznam" class="navbar-item">Vsi zapisi</RouterLink>
            <RouterLink to="/novo-obvestilo" class="navbar-item">Nova objava</RouterLink>
            <RouterLink v-if="storeAuth.isTeamEditor" to="/nov-kronicni" class="navbar-item">Nov kronični uporabnik</RouterLink>
            <RouterLink to="/kronicni" class="navbar-item">Kronični uporabniki</RouterLink>
            <RouterLink to="/objave/obvestila" class="navbar-item">Obvestila</RouterLink>
            <RouterLink to="/objave/sporocila" class="navbar-item">Sporočila</RouterLink>
            <div class="navbar-item has-dropdown is-hoverable">
                <span class="navbar-link">Orodja</span>
                <div class="navbar-dropdown">
                    <RouterLink v-if="storeAuth.isTeamEditor" to="/izvoz" class="navbar-item">Izvoz podatkov</RouterLink>
                    <RouterLink v-if="storeAuth.isTeamEditor" to="/nov-svetovalec" class="navbar-item">Urejanje svetovalcev</RouterLink>
                </div>
            </div>
            
            </div>

            <div class="navbar-end">
                <div class="navbar-item has-dropdown is-hoverable">
                    <span class="navbar-link">{{ storeAuth.user.name }}</span>
                    <div class="navbar-dropdown is-right">
                        <RouterLink to="/spremeni-geslo" class="navbar-item">Sprememba gesla</RouterLink>
                        <a class="navbar-item" @click.prevent="storeAuth.logoutUser">
                            Odjava
                        </a>
                        <hr class="dropdown-divider">
                        <RouterLink to="/kos" class="navbar-item">Koš</RouterLink>
                    </div>
                </div>
                
            </div>
        </div>
        </nav>
</template>

<script setup>

import { useStoreAuth } from '@/stores/storeAuth'
import { ref } from 'vue';
import { RouterLink } from 'vue-router'

const storeAuth = useStoreAuth()

const menuActive = ref(false)

const openMenu = () => {
    menuActive.value = !menuActive.value
}

</script>

<style>

@media ( max-width: 1024px ) {
    .is-active .navbar-item, .is-active .navbar-link {
        color: #000;
}
}

</style>