import { defineStore } from 'pinia'
import { account } from '@/api'

export const useStoreAuth = defineStore( 'storeAuth', {
  
  state: () => ({
    user: {
      teams: []
    },
    allowedRoutes: ['login', 'home'],
    errorMessage:''
    
  }),
  actions: {
    loginUser(credentials) {
      
      const promise = account.createEmailSession( credentials.email, credentials.password)
      
      promise.then( response => {
        this.user.id    = response.userId
        this.user.email = response.email
        this.router.push('/objave/obvestila')
      }, error => {
        this.user = {}
        this.errorMessage = error.message
      })

    },
    logoutUser() {

      const promise = account.deleteSessions();

      promise.then( response =>  {
          this.user = {}
          this.router.replace('/vstop')
      }, error => {
         console.log(error); // Failure
      })
    
    }
    
  },
  getters: {
    isTeamEditor: ( state ) => {
      if( state.user.teams ) {
        return state.user.teams.find( el => el.name == 'Urednik')
      }
      return false
    },
    isTeamLead( state ) {
      if(state.user.teams ) {
        return state.user.teams.find( el => el.name == "Vodja Skupine")
      }
      return false
    },
    getTeamID: ( state ) => {
      if( state.user.teams.length > 0 ) {
        return state.user.teams[0].$id
      } 
      return "0"
    }, 
    isUser: ( state ) => {
      return state.user.hasOwnProperty('id')
    }

  }
})
  